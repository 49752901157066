/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
// @import "~@ionic/angular/css/palettes/dark.system.css";
@import "./theme/variables.scss";
@tailwind base;
@tailwind components;
@tailwind utilities;

@import "~bootstrap-icons/font/bootstrap-icons.css";

@media print {
  .no-print,
  .no-print * {
    display: none !important;
  }

  .print .print * {
    display: block !important;
  }
}

@import "~quill/dist/quill.snow.css";
@import "~quill/dist/quill.bubble.css";

.segment-card-header {
  padding-bottom: 0;
}

.segment-card-title {
  font-size: 16px;
}
// or
// @media (prefers-color-scheme: dark) {
//   .ql-editor.ql-blank::before {
//     @apply text-slate-400;
//   }
//   .ql-snow .ql-fill {
//     @apply fill-slate-400;
//   }
//   .ql-snow .ql-stroke {
//     @apply stroke-slate-400;
//   }
//   .ql-snow .ql-picker {
//     @apply text-slate-400;
//   }

//   .ql-editor p,
//   .ql-editor ol,
//   .ql-editor ul,
//   .ql-editor pre,
//   .ql-editor blockquote,
//   .ql-editor h1,
//   .ql-editor h2,
//   .ql-editor h3,
//   .ql-editor h4,
//   .ql-editor h5,
//   .ql-editor h6 {
//     @apply text-slate-300;
//   }
// }

body.dark {
  .ql-editor.ql-blank::before {
    @apply text-slate-400;
  }
  .ql-snow .ql-fill {
    @apply fill-slate-400;
  }
  .ql-snow .ql-stroke {
    @apply stroke-slate-400;
  }
  .ql-snow .ql-picker {
    @apply text-slate-400;
  }

  .ql-editor p,
  .ql-editor ol,
  .ql-editor ul,
  .ql-editor pre,
  .ql-editor blockquote,
  .ql-editor h1,
  .ql-editor h2,
  .ql-editor h3,
  .ql-editor h4,
  .ql-editor h5,
  .ql-editor h6 {
    @apply text-slate-300;
  }
}

ion-modal.fullscreen {
  --width: 100%;
  --height: 100%;
  --border-radius: 0;
}

ion-header.header-collapse-condense-inactive {
  .header-segment {
    display: none;
  }
}

ion-card-header.ios {
  display: flex;
  flex-flow: column;
}

ion-label strong {
  max-width: calc(100% - 60px);

  overflow: hidden;

  text-overflow: ellipsis;
}

ion-label ion-note {
  display: block;
  font-size: 0.9rem;
}
